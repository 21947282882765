import { Component, OnInit } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-menu-objeto',
  templateUrl: './menu-objeto.page.html',
  styleUrls: ['./menu-objeto.page.scss'],
})
export class MenuObjetoPage implements OnInit {

  id:any;
  data_usuario:any = {};

  constructor(public navCtrl:NavController, private route: ActivatedRoute, private _api: ApiService,
              public loadingCtrl: LoadingController) { }

  ngOnInit() {
    // this.id = this.route.paramMap.get('id');
    this.id = this.route.snapshot.params.id;

    this.getUserValid();   
    
    console.log(this.id);
    
  }  

  async getUserValid(){  
    
    const loading = await this.loadingCtrl.create({
      message: 'Cargando...',
      // duration: 3000,
      spinner: 'circles',
    });

    loading.present();
    
    let user = localStorage.getItem("user");
    this._api.getUser( {id:user} ).subscribe((data:any)=>{
      console.log(data);
      loading.dismiss();
      this.data_usuario = data.data;
    },(err:any)=>{
      loading.dismiss();
    });    
  }

  detalle(){
    this.navCtrl.navigateForward(`detalle-objeto/${this.id}`);
  }

  seguir(){
    this.navCtrl.navigateForward(`seguir-objeto/${this.id}`);
  }

  enviarComando(){
    this.navCtrl.navigateForward(`envio-comandos/${this.id}`);
  }

  geocercas(){
    this.navCtrl.navigateForward(`geocercas-list/${this.id}`);
  }

  //mustra todas y si pasas un id solo las de ese objeto
  alertas(){  
    // this.navCtrl.navigateForward(`geocercas/${this.id}`);
    this.navCtrl.navigateForward(`alertas/1`);
  }


  reportes(){
    this.navCtrl.navigateForward( `reportes/${this.id}` );
  }

  editarObjeto( id:any ){
    this.navCtrl.navigateForward(`editar-objeto/${this.id}`);
  }

}
